import axios from 'axios'
import { API } from '../ENV'

export const postUser = async (email) => {
  try {
    const response = await axios.post(
      `${API}/user`,
      {
        email
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const getUserById = async (userId) => {
  try {
    const response = await axios.get(`${API}/user/id/${userId}`)
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const getUserByUserServiceId = async (userId, fallback = false) => {
  try {
    const response = await axios.get(
      `${API}/user/userService/${userId}?fallback=${fallback}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const getUserByEmail = async (email) => {
  try {
    const response = await axios.get(`${API}/user/email/${email}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    })
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const getUserCount = async () => {
  try {
    const response = await axios.get(`${API}/user/total`)
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const getUserRegistrationCount = async (start, end) => {
  try {
    const response = await axios.get(`${API}/user/registration/${start}/${end}`)
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const getUserDump = async () => {
  try {
    const response = await axios.get(`${API}/user/dump/`)
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const putUser = async (id, setting, value, group) => {
  try {
    const response = await axios.put(
      `${API}/user`,
      {
        id,
        setting,
        value,
        group
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const putUserSubscriptions = async (id) => {
  try {
    const response = await axios.put(
      `${API}/user/subs/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}
