import { useContext, useEffect } from 'react'
import { SocketContext } from '../context/socketContext'
import { PUBLIC_MESSAGE_EVENTS } from '@moal/api'
import {
  ADD_MESSAGE,
  DELETE_MESSAGE,
  EDIT_MESSAGE,
  INITIAL_LOAD,
  LOAD_MORE
} from '../constants/actions/messageListActions'
import { MEMBERS } from '../constants/chatRooms'
import {
  getPublicMessages as getPublicMessagesAPI,
  getPublicMessagesSearch as getPublicMessagesSearchAPI
} from '../utils/APIs/publicMessages'

export const usePublicMessages = ({
  dispatch,
  channel,
  scrollRef,
  isMobileBrowser,
  setKeyModifier,
  modView = false,
  showShadowBanned = false,
  user
}) => {
  const { socket } = useContext(SocketContext)

  useEffect(() => {
    if (channel === MEMBERS && socket) {
      publicSockets()
    } else {
      publicSockets(false)
    }
    return () => {
      publicSockets(false)
    }
  }, [socket, channel])

  const publicSockets = (on = true) => {
    if (on) {
      socket.on(PUBLIC_MESSAGE_EVENTS.POST, handlePostPublicMessage)
      socket.on(PUBLIC_MESSAGE_EVENTS.PUT, handleEditPublicMessage)
      socket.on(PUBLIC_MESSAGE_EVENTS.DELETE, handleDeletePublicMessage)
    } else {
      if (socket) {
        socket.off(PUBLIC_MESSAGE_EVENTS.POST)
        socket.off(PUBLIC_MESSAGE_EVENTS.PUT)
        socket.off(PUBLIC_MESSAGE_EVENTS.DELETE)
      }
    }
  }

  const handlePostPublicMessage = (data) => {
    if (shadowBanCheck(data)) {
      dispatch({ type: ADD_MESSAGE, payload: data })
      if (scrollRef.current === 0 && isMobileBrowser) {
        setKeyModifier((old) => old + 1)
      }
    }
  }

  const handleEditPublicMessage = (data) => {
    if (shadowBanCheck(data)) {
      dispatch({ type: EDIT_MESSAGE, payload: data })
    }
  }

  const handleDeletePublicMessage = (data) => {
    dispatch({ type: DELETE_MESSAGE, payload: data })
  }

  const shadowBanCheck = (message) => {
    return (
      !message.hidden ||
      (user && message.user._id === user._id) ||
      (modView && showShadowBanned)
    )
  }

  const getPublicMessages = async ({ initialLoad, messages, search }) => {
    let loadMore = ''
    if (!initialLoad && messages.length > 0) {
      loadMore = messages[messages.length - 1].postdate
    }

    let response
    if (search) {
      response = await getPublicMessagesSearchAPI({
        search,
        loadMore
      })
    } else {
      response = await getPublicMessagesAPI({
        loadMore
      })
    }

    if (response && response.status === 200) {
      handleGetPublicMessage(initialLoad, response.data)
    }
  }

  const handleGetPublicMessage = (initialLoad, data) => {
    const scrubbed = data.filter((each) => shadowBanCheck(each))

    if (initialLoad) {
      dispatch({ type: INITIAL_LOAD, payload: scrubbed })
    } else {
      dispatch({ type: LOAD_MORE, payload: scrubbed })
    }
  }

  return { getPublicMessages }
}
