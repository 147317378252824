import { putProduct } from '@moal/api'
import { React } from 'react'
import { toast } from 'react-toastify'
import ToggleSwitchBasic from '../../../Forms/common/ToggleSwitchBasic'

const OpenHouse = ({ product, updateState }) => {
  const handleToggle = async () => {
    const data = {
      ...product,
      details: {
        ...product.details,
        openHouse: {
          ...product.details.openHouse,
          enabled: !product.details.openHouse?.enabled
        }
      }
    }

    await putProduct({
      token: localStorage.getItem('authToken'),
      data
    })
    updateState(data)
    toast.success('Open House toggled.')
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2 justify-start">
        <h2>Open House</h2>
        <ToggleSwitchBasic
          value={product.details.openHouse?.enabled || false}
          label=""
          color="green"
          onChange={handleToggle}
        />
      </div>
      {product.details.openHouse?.enabled && (
        <>
          <Subtype product={product} updateState={updateState} />
          <Dates product={product} updateState={updateState} />
        </>
      )}
    </div>
  )
}

const Subtype = ({ product, updateState }) => {
  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = {
      ...product,
      details: {
        ...product.details,
        openHouse: {
          ...product.details.openHouse,
          itemNumber: formData.get('itemNumber')
        }
      }
    }

    await putProduct({
      token: localStorage.getItem('authToken'),
      data
    })
    updateState(data)
    toast.success('Open House Item Number saved.')
  }

  return (
    <div className="flex flex-col gap-2">
      <h3>Open House Item Number (Pub Code)</h3>
      <form
        onSubmit={(e) => handleSubmit(e)}
        autoComplete="off"
        className="flex gap-2"
      >
        <input
          id="itemNumber"
          name="itemNumber"
          defaultValue={product.details.openHouse.itemNumber}
          className="input block w-full"
          placeholder="Open House Item Number"
        />
        <button type="submit" className="primary-button w-fit">
          Save
        </button>
      </form>
    </div>
  )
}

const Dates = ({ product, updateState }) => {
  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = {
      ...product,
      details: {
        ...product.details,
        openHouse: {
          ...product.details.openHouse,
          startDate: formData.get('startDate'),
          endDate: formData.get('endDate')
        }
      }
    }

    await putProduct({
      token: localStorage.getItem('authToken'),
      data
    })
    updateState(data)
    toast.success('Open House Date Range saved.')
  }

  return (
    <div className="flex flex-col gap-2">
      <h3>Open House Date Range</h3>
      <form
        onSubmit={(e) => handleSubmit(e)}
        autoComplete="off"
        className="flex gap-2"
      >
        <label className="block w-full" htmlFor="start">
          <h4>Start</h4>
          <input
            id="startDate"
            name="startDate"
            type="date"
            defaultValue={product.details.openHouse.startDate}
            className="input block w-full"
          />
        </label>
        <label className="block w-full" htmlFor="end">
          <h4>End</h4>
          <input
            id="endDate"
            name="endDate"
            type="date"
            defaultValue={product.details.openHouse.endDate}
            className="input block w-full"
          />
        </label>
        <button type="submit" className="primary-button w-fit mt-auto">
          Save
        </button>
      </form>
    </div>
  )
}

export default OpenHouse
