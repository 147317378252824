import htmlTruncate from 'html-truncate'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import * as REGEX from '../../../constants/regex'
import { AuthenticationContext } from '../../../context/authenticationContext'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import { hasStaffPrivilege } from '../../../utils/RequireRole'
import { appendStoredQueryParams } from '../../../utils/utilities'

const TextWrapper = ({
  text,
  fromUser,
  allGreen = false,
  renderLinks = false,
  element = 'div',
  truncate = false
}) => {
  const { user } = useContext(AuthenticationContext)
  const { botMessages } = useContext(SiteConfigContext)

  const replacer = (text) => {
    const httpsLink =
      text.includes('https://') || text.includes('http://')
        ? text
        : `https://${text}`
    return `<a href="${appendStoredQueryParams(
      httpsLink
    )}" class="link" target="_blank" rel="noreferrer">${text}</a>`
  }

  const botReplacer = (text) => {
    let newText = text
    if (text && text.includes('!')) {
      newText = processBotReplacer(text)
    }
    return newText
  }

  const processBotReplacer = (message) => {
    let newText = message
    const lcMessage = message.toLowerCase()

    const allTriggers = [
      ...new Set(
        [...lcMessage.matchAll(REGEX.BOT_REGEX)].map((match) => match[0])
      )
    ]

    if (allTriggers.length > 0) {
      if (botMessages) {
        const triggerCounts = {}

        allTriggers.forEach((each) => {
          const match = botMessages
            .flatMap((service) => service.messages) // Flatten all messages arrays
            .find((bot) => isExactMatch(bot.trigger, each))

          if (match) {
            const trigger = match.trigger.toLowerCase()
            if (!triggerCounts[trigger]) {
              triggerCounts[trigger] = 0
            }

            const triggerRegex = new RegExp(`!${trigger}\\b`, 'gi')
            const replacement = (matchStr) => {
              if (triggerCounts[trigger] === 0 || allGreen) {
                triggerCounts[trigger]++
                return `<span class='text-lime-600 dark:text-lime-500'>${matchStr}</span>`
              } else {
                return `<span class='text-yellow-600 dark:text-yellow-500'>${matchStr}</span>`
              }
            }

            newText = newText.replace(triggerRegex, replacement)
          }
        })
      }
    }

    return newText
  }

  const tagReplacer = (text) => {
    let newText = text
    if (text.includes('@')) {
      newText = processTagReplacer(text)
    }
    return newText
  }

  const processTagReplacer = (message) => {
    let newText = message
    if (user) {
      const regex = new RegExp(`@${user.userService.profile.name}\\b`, 'ig')
      const found = message.match(regex)
      if (found) {
        newText = newText.replace(
          regex,
          `<span class='text-blue-600 dark:text-blue-500'>@${user.userService.profile.name}</span>`
        )
      }
    }
    return newText
  }

  const isExactMatch = (trigger, string) => {
    const regex = new RegExp(`!${trigger.toLowerCase()}\\b`, 'i')
    return new RegExp(regex).test(string.toLowerCase())
  }

  const processURLs = (text) => {
    const urlRegex = new RegExp(REGEX.URL, 'g')

    // Split the text into parts that are inside and outside <a> tags
    const parts = text.split(/(<a[^>]*>.*?<\/a>)/gi)
    return parts
      .map((part) => {
        if (part.startsWith('<a') && part.endsWith('</a>')) {
          // Add class="link" to existing <a> tags
          if (!part.includes('class="link"')) {
            part = part.replace('<a ', '<a class="link" ')
          }
          return part // Return the part as is with the class added
        } else {
          return part.replace(urlRegex, replacer) // Process only the parts outside <a> tags
        }
      })
      .join('')
  }

  const formatText = (text) => {
    let newMessage = text

    if (renderLinks || (fromUser && hasStaffPrivilege(fromUser, true))) {
      newMessage = processURLs(newMessage)
    }

    newMessage = botReplacer(newMessage)
    newMessage = tagReplacer(newMessage)
    return newMessage
  }

  if (element === 'p') {
    return (
      <p
        className="inline [&>p]:mb-4"
        dangerouslySetInnerHTML={{
          __html: truncate
            ? htmlTruncate(formatText(text), 500)
            : formatText(text)
        }}
      />
    )
  }

  return (
    <div
      className="inline [&>p]:mb-4"
      dangerouslySetInnerHTML={{
        __html: truncate
          ? htmlTruncate(formatText(text), 500)
          : formatText(text)
      }}
    />
  )
}

TextWrapper.propTypes = {
  text: PropTypes.string.isRequired,
  fromUser: PropTypes.object,
  allGreen: PropTypes.bool,
  renderLinks: PropTypes.bool,
  element: PropTypes.string
}

export default TextWrapper
