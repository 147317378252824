import {
  faArrowUpRightFromSquare,
  faSpinner
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useState } from 'react'
import * as SETTINGS from '../../../constants/settings'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import Error from '../../Utilities/Error'

import { PRODUCT_TYPES } from '@moal/api'
import { AuthenticationContext } from '../../../context/authenticationContext'
import { removeSlashFromUrl } from '../../../utils/utilities'

const EmailForm = ({ turnstile }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({
    state: false,
    data: {}
  })
  const { signUp } = useContext(AuthenticationContext)
  const { productsService, [SETTINGS.PHONE_NUMBERS.key]: phoneNumbers } =
    useContext(SiteConfigContext)
  const [email, setEmail] = useState('')

  const hubURL = removeSlashFromUrl(
    productsService[PRODUCT_TYPES.HUB].urls.website
  )

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError({
      state: false,
      data: {}
    })

    // ⭐⭐⭐ KEVIN CHECK MY WORK HERE
    const response = await signUp({
      email,
      signUpList: {
        listCodes: productsService[PRODUCT_TYPES.PRODUCT].details.listCodes,
        sourceId: productsService[PRODUCT_TYPES.PRODUCT].details.sourceId,
        emailOversightId:
          productsService[PRODUCT_TYPES.PRODUCT].details.emailOversightId
      }
    })

    if (!response || response.success === false) {
      setError({
        state: true,
        data: response.data
      })
    }
    setLoading(false)
  }

  const handleReset = () => {
    setError({
      state: false,
      data: {}
    })
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      {error.state && (
        <Error
          message={error.data.message}
          extras={error.data.extras}
          phoneNumbers={phoneNumbers}
        />
      )}
      {error?.data?.extras?.hideSignUp ? (
        <div className="link cursor-pointer" onClick={handleReset}>
          <strong>Try again?</strong>
        </div>
      ) : (
        <div className={!turnstile ? 'p-2' : ''}>
          {hubURL && (
            <div>
              <p className="text-sm mb-2">
                By submitting your email address, you will receive a free
                subscription to <em>{process.env.REACT_APP_PRODUCT_NAME}</em>,
                and offers from us and our affiliates that we think might
                interest you. You can unsubscribe at any time.{' '}
                <a
                  href={`${hubURL}/privacy-policy`}
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                >
                  <strong>
                    Privacy Policy
                    <FontAwesomeIcon
                      icon={faArrowUpRightFromSquare}
                      className="ml-1 w-3 h-3"
                    />
                  </strong>
                </a>
              </p>
            </div>
          )}
          <div className="flex md:flex-col lg:flex-row gap-2">
            <input
              className="input w-full"
              name="email"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {loading ? (
              <span className="flex items-center justify-center">
                <span className="sr-only">Validating...</span>
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="animate-spin h-4 text-neutral-500"
                />
              </span>
            ) : (
              <button
                type="submit"
                className="primary-button text-lg whitespace-nowrap md:w-full lg:w-auto"
                disabled={loading}
                required
              >
                Sign Up
              </button>
            )}
          </div>
          {hubURL && (
            <div>
              <p className="text-sm mt-2">
                <a
                  href={`${hubURL}/do-not-sell`}
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                >
                  <strong>
                    Do Not Sell or Share My Personal Information
                    <FontAwesomeIcon
                      icon={faArrowUpRightFromSquare}
                      className="ml-1 w-3 h-3"
                    />
                  </strong>
                </a>
              </p>
            </div>
          )}
        </div>
      )}
    </form>
  )
}

export default EmailForm
