import React, { useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import CountdownBar from '../CountdownBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronCircleDown,
  faChevronCircleUp,
  faCircleCheck,
  faSquarePollHorizontal,
  faCircleDot
} from '@fortawesome/pro-solid-svg-icons'
import { AuthenticationContext } from '../../../context/authenticationContext'
import Countdown from '../Countdown'
import { hasStaffPrivilege } from '../../../utils/RequireRole'
import { postPollVote, putPoll } from '../../../utils/APIs/polls'
import { CATEGORIES } from '../../../constants/analytics'
import { AnalyticsContext } from '../../../context/analyticsContext'

const COUNTER_DEFAULT = 300

function getStatusWidth(votes, totalVotes) {
  return ((votes / totalVotes) * 100).toFixed(0) + '%'
}

const Poll = ({ _id, prompt, choices, isOpen, votes, endTime, isArchived }) => {
  const { pushEvent } = useContext(AnalyticsContext)
  const { user } = useContext(AuthenticationContext)
  const [collapsed, setCollapsed] = useState(false)
  const [counter, setCounter] = useState()

  useEffect(() => {
    if (!isOpen && !isArchived) {
      setCounter(COUNTER_DEFAULT)
    } else {
      setCounter(null)
    }
  }, [isOpen])

  const onCountdownBarComplete = async () => {
    await putPoll(_id, false, true)
  }

  const getHasVoted = useMemo(() => {
    if (user) {
      return votes.some((vote) => vote.user === user._id)
    }
  }, [votes, user])

  const userVote = useMemo(() => {
    if (user) {
      return votes.find((vote) => vote.user === user._id) || undefined
    }
  }, [votes, user])

  const castVote = async (choice) => {
    if (user) {
      if (isOpen === false || isArchived === true) {
        return
      }
      if (new Date(endTime) < new Date()) {
        return
      }
      // assemle our vote payload
      const data = {
        pollId: _id,
        choiceId: choice._id,
        userId: user._id
      }
      const response = await postPollVote(data)
      if (response && response.status === 201) {
        pushEvent(CATEGORIES.POLLS, 'VOTE', user.userService._id)
      }
    }
  }

  const handleArchive = async () => {
    if (isOpen === true) {
      await putPoll(_id, false, false, endTime)
    } else {
      await putPoll(_id, false, true, endTime)
    }
  }

  return (
    <div className="flex flex-col bg-white dark:bg-neutral-900">
      {counter && (
        <CountdownBar
          timeInSeconds={COUNTER_DEFAULT}
          framesPerSecond={60}
          onComplete={onCountdownBarComplete}
        />
      )}
      <div className="py-1 text-sm bg-neutral-100 dark:bg-neutral-800">
        <div className="flex items-center justify-between">
          {hasStaffPrivilege(user) && (
            <button onClick={handleArchive} className="flex items-center">
              <div className="flex items-center gap-1 py-0.5 px-1">
                <FontAwesomeIcon
                  className="mt-1 mx-1 h-5 transition-all hover:text-red-600 active:text-red-800 dark:text-white"
                  icon={faSquarePollHorizontal}
                />{' '}
                <p className="text-sm italic">
                  Current Poll{' '}
                  {isOpen === true && new Date(endTime) > new Date() ? (
                    <span className="text-green-600 dark:text-green-400">
                      (Open)
                    </span>
                  ) : (
                    <span className="text-red-600 dark:text-red-400">
                      (Closed)
                    </span>
                  )}
                </p>
                {isOpen === true && new Date(endTime) > new Date() && (
                  <Countdown
                    endDate={endTime}
                    endEvent={async () => putPoll(_id, false, false, endTime)}
                  />
                )}
              </div>
            </button>
          )}
          {!hasStaffPrivilege(user) && (
            <div className="flex items-center gap-1 py-0.5 px-1">
              <FontAwesomeIcon
                className="ml-2 h-6 transition-all dark:text-white"
                icon={faSquarePollHorizontal}
              />{' '}
              <p className="text-sm italic">
                Poll{' '}
                {isOpen === true && new Date(endTime) > new Date() ? (
                  <span className="text-green-600 dark:text-green-400">
                    (Open)
                  </span>
                ) : (
                  <span className="text-red-600 dark:text-red-400">
                    (Closed)
                  </span>
                )}
              </p>
              {isOpen === true && new Date(endTime) > new Date() && (
                <Countdown endDate={endTime} />
              )}
            </div>
          )}
          {
            <button
              onClick={() => setCollapsed(!collapsed)}
              className="flex items-center"
            >
              <FontAwesomeIcon
                className="h-4 mr-1 transition-all text-neutral-500 dark:text-neutral-500 hover:text-neutral-700 hover:dark:text-neutral-300"
                icon={collapsed ? faChevronCircleDown : faChevronCircleUp}
              />
            </button>
          }
        </div>
        <h4 className="font-semibold px-3 pb-1">{prompt}</h4>
        {!collapsed && (
          <div>
            {choices.map((choice) => (
              <Choice
                choice={choice}
                votes={votes.filter((vote) => vote.option === choice._id) || []}
                totalVoteCount={votes.length}
                canVote={user !== null && !getHasVoted}
                selected={!!(userVote && userVote.option === choice._id)}
                key={choice._id}
                handleOnSelect={() => castVote(choice)}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

Poll.propTypes = {
  _id: PropTypes.string.isRequired,
  prompt: PropTypes.string.isRequired,
  choices: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  votes: PropTypes.array.isRequired,
  createdAt: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  isArchived: PropTypes.bool.isRequired
}

const Choice = ({
  choice,
  votes,
  totalVoteCount,
  canVote,
  selected,
  handleOnSelect
}) => {
  const statusWidth =
    totalVoteCount === 0 ? '0%' : getStatusWidth(votes.length, totalVoteCount)

  return (
    <div name="options" className="px-2 pb-1 w-full flex flex-col gap-1">
      <div
        onKeyDown={(e) => {
          if ((e.code === 'Enter' || e.code === 'Space') && canVote) {
            handleOnSelect()
          }
        }}
        onClick={() => canVote && handleOnSelect()}
        tabIndex={0}
        name="choice"
        className={`${
          canVote && 'cursor-pointer hover:bg-black/5 dark:hover:bg-white/5'
        } grid gap-2 items-start p-0.5'`}
        style={{ gridTemplateColumns: 'min-content auto' }}
      >
        <div>
          <FontAwesomeIcon
            icon={selected ? faCircleCheck : faCircleDot}
            className={`${
              canVote
                ? 'text-neutral-800 dark:text-neutral-200 hover:text-blue-500'
                : selected
                  ? 'text-blue-500'
                  : 'text-neutral-300 dark:text-neutral-600'
            }`}
          />
        </div>
        <div>
          <p className={`${selected && 'font-semibold'} text-sm`}>
            {choice.option}
            {votes.length > 0 &&
              ` (${votes.length} ${votes.length > 1 ? 'votes' : 'vote'})`}
          </p>
        </div>
      </div>
      {votes.length > 0 && (
        <div
          className="grid gap-1 items-center"
          style={{ gridTemplateColumns: '2.75rem auto' }}
        >
          <div className="bg-neutral-200 dark:bg-neutral-700 p-0.5 rounded">
            <p className="text-sm whitespace-nowrap text-center">
              {statusWidth}
            </p>
          </div>
          <div className="relative w-full h-3 bg-neutral-300 dark:bg-neutral-600 rounded-full">
            <div
              className="absolute top-0 left-0 h-full bg-gradient-to-r from-brand-secondary-500 to-brand-primary-500 dark:from-brand-secondary-600 dark:to-brand-primary-600 rounded-full"
              style={{ width: statusWidth }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

Choice.propTypes = {
  choice: PropTypes.object.isRequired,
  votes: PropTypes.array.isRequired,
  totalVoteCount: PropTypes.number.isRequired,
  canVote: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  handleOnSelect: PropTypes.func.isRequired
}

export default Poll
