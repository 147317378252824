import { nanoid } from 'nanoid'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useReducer } from 'react'
import io from 'socket.io-client'
import { AuthenticationContext } from '../context/authenticationContext'
import { initialState, socketReducer } from '../reducers/socketReducer'
import { delay } from '../utils/utilities'

import { GLOBAL_EVENTS, LIVESTREAM_EVENTS } from '@moal/api'

import {
  R_ERROR,
  R_LOADING,
  R_SOCKET
} from '../constants/actions/socketActions'
import { SiteConfigContext } from './siteConfigContext'

export const SocketContext = React.createContext({})

export const SocketProvider = ({ children }) => {
  const { setSettings, activePoll, updateActivePoll } =
    useContext(SiteConfigContext)
  const { user, setFreshUserData } = useContext(AuthenticationContext)
  const [state, dispatch] = useReducer(socketReducer, initialState)

  useEffect(() => {
    if (state.loading) {
      connectToSocket(process.env.REACT_APP_SOCKET_URL, 'socket')
      connectToSocket(process.env.REACT_APP_TRADES_SOCKET_URL, 'tradesSocket')
      if (user) {
        connectToSocket(
          process.env.REACT_APP_PRIVATE_MESSAGES_SOCKET_URL,
          'privateMessagesSocket'
        )
        connectToSocket(
          process.env.REACT_APP_ANNOUNCEMENTS_SOCKET_URL,
          'announcementsSocket'
        )
      }
    }
  }, [user])

  useEffect(() => {
    if (state.socket) {
      // state.socket.emit(GLOBAL_EVENTS.CONNECTED, {
      //   user
      // })

      state.socket.on(GLOBAL_EVENTS.CONNECT_ERROR, function () {
        dispatch({
          type: R_ERROR,
          payload: 'Failed to connect to server. [code: C]'
        })
      })

      state.socket.on(GLOBAL_EVENTS.ERROR, async function (data) {
        dispatch({ type: R_ERROR, payload: data })
        await delay(5000)
        connectToSocket(process.env.REACT_APP_SOCKET_URL, 'socket')
      })

      state.socket.on(LIVESTREAM_EVENTS.PUT_USER, handlePutUser)
      state.socket.on(LIVESTREAM_EVENTS.UPDATE_SETTINGS, handleUpdateSettings)
    }
    return () => {
      if (state.socket) {
        state.socket.off(LIVESTREAM_EVENTS.PUT_USER, handlePutUser)
        state.socket.off(
          LIVESTREAM_EVENTS.UPDATE_SETTINGS,
          handleUpdateSettings
        )
      }
    }
  }, [state.socket])

  useEffect(() => {
    if (state.socket) {
      state.socket.on(LIVESTREAM_EVENTS.UPDATE_POLL, handleUpdatePoll)
    }
    return () => {
      if (state.socket) {
        state.socket.off(LIVESTREAM_EVENTS.UPDATE_POLL, handleUpdatePoll)
      }
    }
  }, [state.socket, activePoll])

  useEffect(() => {
    if (state.privateMessagesSocket) {
      state.privateMessagesSocket.emit(GLOBAL_EVENTS.CONNECTED, {
        user
      })

      state.privateMessagesSocket.on(GLOBAL_EVENTS.CONNECT_ERROR, function () {
        dispatch({
          type: R_ERROR,
          payload: 'Failed to connect to server. [code: P]'
        })
      })

      state.privateMessagesSocket.on(
        GLOBAL_EVENTS.ERROR,
        async function (data) {
          dispatch({ type: R_ERROR, payload: data })
          await delay(5000)
          connectToSocket(
            process.env.REACT_APP_PRIVATE_MESSAGES_SOCKET_URL,
            'privateMessagesSocket'
          )
        }
      )
    }
    return () => {
      if (state.privateMessagesSocket) {
        state.privateMessagesSocket.off(GLOBAL_EVENTS.CONNECTED)
      }
    }
  }, [state.privateMessagesSocket])

  useEffect(() => {
    if (user && state.announcementsSocket) {
      state.announcementsSocket.emit(GLOBAL_EVENTS.CONNECTED, {
        user
      })
    }
  }, [state.announcementsSocket, user])

  useEffect(() => {
    if (state.tradesSocket) {
      // state.tradesSocket.emit(GLOBAL_EVENTS.CONNECTED, {
      //   user
      // })

      state.tradesSocket.on(GLOBAL_EVENTS.CONNECT_ERROR, function () {
        dispatch({
          type: R_ERROR,
          payload: 'Failed to connect to server. [code: T]'
        })
      })

      state.tradesSocket.on(GLOBAL_EVENTS.ERROR, async function (data) {
        dispatch({ type: R_ERROR, payload: data })
        await delay(5000)
        connectToSocket(process.env.REACT_APP_TRADES_SOCKET_URL, 'tradesSocket')
      })
    }
    return () => {
      if (state.tradesSocket) {
        //   state.tradesSocket.off(GLOBAL_EVENTS.CONNECTED)
      }
    }
  }, [state.tradesSocket])

  const connectToSocket = (url, type) => {
    const newSocket = io.connect(url, {
      transports: ['websocket'],
      auth: {
        user: user?._id,
        token: localStorage.getItem('authToken'),
        nano: getSocketNano()
      }
    })
    dispatch({ type: R_SOCKET, payload: { type, socket: newSocket } })
    dispatch({ type: R_LOADING, payload: false })
    dispatch({ type: R_ERROR, payload: null })
  }

  const getSocketNano = () => {
    const socketNano = localStorage.getItem('socketNano')
    if (!socketNano) {
      const newNano = nanoid()
      localStorage.setItem('socketNano', newNano)
      return newNano
    }
    return socketNano
  }

  const handlePutUser = (data) => {
    if (user && data.id === user._id) {
      setFreshUserData()
    }
  }

  const handleUpdateSettings = (data) => {
    setSettings(data)
  }

  const handleUpdatePoll = (data) => {
    updateActivePoll(data)
  }

  return (
    <SocketContext.Provider
      value={{
        socket: state.socket,
        socketError: state.error,
        privateMessagesSocket: state.privateMessagesSocket,
        tradesSocket: state.tradesSocket,
        announcementsSocket: state.announcementsSocket
      }}
    >
      {children}
    </SocketContext.Provider>
  )
}

SocketProvider.propTypes = {
  children: PropTypes.any
}
