import { putProduct } from '@moal/api'
import React from 'react'
import { toast } from 'react-toastify'

const Free = ({ product, updateState }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2 justify-start">
        <h2>Free</h2>
        <SignUpList product={product} updateState={updateState} />
      </div>
    </div>
  )
}

const SignUpList = ({ product, updateState }) => {
  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = {
      ...product,
      details: {
        ...product.details,
        listCodes: formData.get('listCodes'),
        sourceId: formData.get('sourceId'),
        emailOversightId: formData.get('emailOversightId')
      }
    }

    await putProduct({
      token: localStorage.getItem('authToken'),
      data
    })
    updateState(data)
    toast.success('Free Sign Up List saved.')
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-2">
        <h3>Free Sign Up List</h3>
        <form
          onSubmit={(e) => handleSubmit(e)}
          autoComplete="off"
          className="flex gap-2"
        >
          <label className="block w-full" htmlFor="listCodes">
            <h4>List Code</h4>
            <input
              id="listCodes"
              name="listCodes"
              placeholder="List Code"
              defaultValue={product.details.listCodes}
              className="input block w-full"
            />
          </label>
          <label className="block w-full" htmlFor="sourceId">
            <h4>Source ID</h4>
            <input
              id="sourceId"
              name="sourceId"
              placeholder="Source ID"
              defaultValue={product.details.sourceId}
              className="input block w-full"
            />
          </label>
          <label className="block w-full" htmlFor="sourceId">
            <h4>Email Oversight ID</h4>
            <input
              id="emailOversightId"
              name="emailOversightId"
              placeholder="Email Oversight ID"
              defaultValue={product.details.emailOversightId}
              className="input block w-full"
            />
          </label>
          <button type="submit" className="primary-button w-fit mt-auto">
            Save
          </button>
        </form>
      </div>
      <div className="flex">
        <p className="text-sm text-red-500">
          <strong>NOTE:</strong> Please put the primary list code first.
        </p>
      </div>
    </div>
  )
}

export default Free
