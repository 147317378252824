import axios from 'axios'
import { API } from '../ENV'

export const postAuthLogin = async (authCode) => {
  try {
    const response = await axios.post(
      `${API}/auth/get-token`,
      {
        authCode
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const postAuthPersist = async (token) => {
  try {
    const response = await axios.post(
      `${API}/auth/persist`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          token
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const postAuthSignUp = async (email, signUpList) => {
  try {
    const response = await axios.post(
      `${API}/auth/sign-up`,
      {
        email,
        signUpList
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}
