import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { getPoll } from '../../../utils/APIs/polls'
import Poll from '../../Utilities/Poll'
import { SocketContext } from '../../../context/socketContext'
import { LIVESTREAM_EVENTS } from '@moal/api'

function sortVotesByChoice(choices, votes) {
  const choiceOrder = choices.map((choice) => choice._id)

  // Sort the votes based on the order of choices in the choiceOrder array
  votes.sort((a, b) => {
    const choiceAIndex = choiceOrder.indexOf(a.option)
    const choiceBIndex = choiceOrder.indexOf(b.option)

    // Compare the indexes of the choices
    return choiceAIndex - choiceBIndex
  })

  return votes
}

export default function Results() {
  const { pollId } = useParams()
  const { socket } = useContext(SocketContext)
  const [poll, setPoll] = useState(null)
  const [votes, setVotes] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (socket) {
      socket.on(LIVESTREAM_EVENTS.UPDATE_POLL, handleUpdatePoll)
    }
    return () => {
      if (socket) {
        socket.off(LIVESTREAM_EVENTS.UPDATE_POLL, handleUpdatePoll)
      }
    }
  }, [socket, poll])

  useEffect(() => {
    const fetchPoll = async () => {
      try {
        const response = await getPoll(pollId)
        if (response && response.status === 200) {
          updateState(response.data)
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
      }
    }
    fetchPoll()
  }, [pollId])

  const updateState = (data) => {
    setVotes(sortVotesByChoice(data.choices, data.votes))
    setPoll(data)
  }

  const handleUpdatePoll = (data) => {
    if (poll && data.poll._id === poll._id) {
      updateState(data.poll)
    }
  }

  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="flex flex-col gap-2 w-96 max-2-full">
          <h1>Poll Results</h1>
          <h2>{poll.prompt}</h2>
          <h3>Total votes: {votes.length}</h3>
          <h3>Created: {new Date(poll.createdAt).toLocaleString()}</h3>
          <Poll {...poll} />
        </div>
      )}
    </div>
  )
}
