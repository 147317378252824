import axios from 'axios'
import { API } from '../ENV'

export const postBotMessage = async (trigger, message) => {
  try {
    const response = await axios.post(`${API}/botMessage`, {
      trigger: trigger.toLowerCase(),
      message
    })
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const getBotMessages = async () => {
  try {
    const response = await axios.get(`${API}/botMessage`)
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const deleteBotMessage = async (id) => {
  try {
    const response = await axios.delete(`${API}/botMessage/${id}`)
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}
