import { Switch } from '@headlessui/react'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { SiteConfigContext } from '../../../../context/siteConfigContext'
import { putUser } from '../../../../utils/APIs/users'

const ToggleSwitch = ({
  setting,
  value,
  label,
  color,
  userId,
  group,
  callbackFunction
}) => {
  const { updateSetting } = useContext(SiteConfigContext)

  const changeSetting = async (setting, value) => {
    if (userId) {
      if (group) {
        const response = await putUser(userId, setting, value, group)
        if (response && response.status === 200) {
          if (callbackFunction) {
            callbackFunction(response.data)
          }
        }
      } else {
        const response = await putUser(userId, setting, value)
        if (response && response.status === 200) {
          if (callbackFunction) {
            callbackFunction(response.data)
          }
        }
      }
    } else {
      await updateSetting(setting, value)
    }
  }

  return (
    <Switch.Group>
      <div className="flex items-center">
        <Switch.Label className="text-sm font-semibold mr-4 dark:text-neutral-100">
          {label}
        </Switch.Label>
        <Switch
          checked={value}
          onChange={() => changeSetting(setting, !value)}
          className={`${value ? `bg-${color}-500` : 'bg-neutral-500'}
          relative ml-auto inline-flex h-2 w-11 items-center rounded-full`}
        >
          <span className="sr-only">{label}</span>
          <span
            className={`${value ? 'translate-x-6' : 'translate-x-1'}
          inline-flex h-4 w-4 rounded-full border border-neutral-900/40 dark:border-none bg-neutral-100 transition-all justify-center items-center`}
          >
            <span
              className={`${
                value ? `bg-${color}-500` : 'bg-neutral-500'
              } h-2.5 w-2.5 rounded-full z-10`}
            />
          </span>
        </Switch>
      </div>
    </Switch.Group>
  )
}

ToggleSwitch.propTypes = {
  setting: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  value: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  userId: PropTypes.string,
  group: PropTypes.string
}

export default ToggleSwitch
