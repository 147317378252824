import { PRODUCT_TYPES } from '@moal/api'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useReducer } from 'react'
import {
  LOGGED_OUT,
  PAGE_LOAD,
  USER
} from '../constants/actions/authenticationActions'
import {
  authenticationReducer,
  initialState
} from '../reducers/authenticationReducer'
import {
  postAuthLogin,
  postAuthPersist,
  postAuthSignUp
} from '../utils/APIs/authorization'
import { getUserById, putUser } from '../utils/APIs/users'
import { preserveWhitelistedParams } from '../utils/utilities'
import { AnalyticsContext } from './analyticsContext'
import { SiteConfigContext } from './siteConfigContext'

export const AuthenticationContext = React.createContext({})

export const AuthenticationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authenticationReducer, initialState)
  const { freeEnabled, productsService } = useContext(SiteConfigContext)
  const { persistTrackingParams } = useContext(AnalyticsContext)
  const { search } = window.location
  const urlParams = new URLSearchParams(search)
  const code = urlParams.get('code')
  const jwt = localStorage.getItem('authToken')

  useEffect(() => {
    processAuth()
  }, [jwt, code])

  const processAuth = async () => {
    if (jwt) {
      await persist()
    } else if (code) {
      await login()
    }
    // After we process the auth, we want to handle any query params
    // First we will persist any tracking params. We will blacklist any non-tracking params
    persistTrackingParams(search, ['code', 'channel'])
    // Next we will remove query params from the URL, leaving only whitelisted params in place
    preserveWhitelistedParams(search, ['channel'])

    dispatch({ type: PAGE_LOAD, payload: false })
  }

  const login = async () => {
    // if no jwt, but code is present, exchange for jwt
    const authCode = code.split(':')[1]
    const itemNumber = code.split(':')[0]
    if (itemNumber !== process.env.REACT_APP_PRODUCT_CODE) {
      console.error('Invalid item number')
      dispatch({
        type: LOGGED_OUT,
        payload: 'Something went wrong please try again.'
      })
      return null
    }
    const response = await postAuthLogin(authCode)
    if (response && response.status === 201) {
      const { user, token } = response.data
      localStorage.setItem('authToken', token)
      updateUser(user)
    } else {
      dispatch({
        type: LOGGED_OUT,
        payload:
          response?.data?.errors?.[0]?.message ??
          'Something went wrong please try again.'
      })
    }
  }

  const signUp = async ({ email, signUpList }) => {
    if (email && signUpList) {
      try {
        const response = await postAuthSignUp(email, signUpList)

        if (response && response.status === 201) {
          const { user, token } = response.data
          localStorage.setItem('authToken', token)
          updateUser(user)
          return {
            success: true,
            data: response.data
          }
        } else {
          return {
            success: false,
            data: response.data.errors[0].message
          }
        }
      } catch (error) {
        return {
          success: false,
          data: {
            message: "Couldn't process signup...",
            extras: {
              support: true
            }
          }
        }
      }
    }
  }

  const persist = async () => {
    // check if there is data in LS
    const token = localStorage.getItem('authToken')
    if (token) {
      const response = await postAuthPersist(token)
      if (response && response.status === 200) {
        dispatch({ type: USER, payload: response.data })
      } else {
        localStorage.removeItem('authToken')
        dispatch({
          type: LOGGED_OUT,
          payload:
            response?.data?.errors?.[0]?.message ?? 'Session has expired.'
        })
      }
    }
  }

  const logout = async () => {
    localStorage.removeItem('authToken')
    if (freeEnabled) {
      window.location.reload(true)
    } else {
      window.location.href = `${process.env.REACT_APP_LOGIN_PORTAL_URL}?logout=true`
    }
  }

  const setFreshUserData = async () => {
    const response = await getUserById(state.user._id)
    if (response && response.status === 200) {
      updateUser(response.data)
    }
  }

  const updateUser = async (newData) => {
    dispatch({ type: USER, payload: newData })
  }

  const endTimeOut = async () => {
    const response = await putUser(
      state.user._id,
      'timeout',
      false,
      'moderation'
    )
    if (response && response.status === 200) {
      updateUser(response.data)
    }
  }

  const getSubType = (user = null) => {
    const currentUser = user || state.user
    if (!currentUser) return null

    const subscriptions = currentUser.userService.subscriptions
    const productCode = process.env.REACT_APP_PRODUCT_CODE

    const match = subscriptions.find((x) => x.itemNumber === productCode)
    if (match) {
      return match.subType
    }

    const openHouseItemNumber =
      productsService[PRODUCT_TYPES.PRODUCT].details?.openHouse?.itemNumber
    if (openHouseItemNumber) {
      const ohMatch = subscriptions.find(
        (x) => x.itemNumber === openHouseItemNumber
      )
      if (ohMatch) return openHouseItemNumber
    }

    return null
  }

  return (
    <AuthenticationContext.Provider
      value={{
        user: state.user,
        getSubType,
        pageLoad: state.pageLoad,
        loggedOut: state.loggedOut,
        signUp,
        persist,
        logout,
        updateUser,
        setFreshUserData,
        endTimeOut
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}

AuthenticationProvider.propTypes = {
  children: PropTypes.any
}
