import {
  faCircleCheck,
  faCircleXmark,
  faExclamationTriangle
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { editAnnouncement, postAnnouncement } from '@moal/api'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { postAnnouncementValidation } from '../../../utils/postAnnouncementHelpers'
import { sanitizeText } from '../../../utils/utilities'
import ReactQuillEditor from '../../Utilities/ReactQuillEditor'

const ANNOUNCEMENT_FIELDS_MAX_LENGTH = { TITLE: 80, MESSAGE: 500, CTA_TEXT: 36 }

const defaultValues = {
  title: '',
  message: '',
  cta: { url: '', text: '' },
  sendNotifications: true,
  itemNumber: process.env.REACT_APP_PRODUCT_CODE
}

const PostAnnouncementForm = ({ edit, closeModal, prefill }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({
    state: false,
    message: ''
  })

  const [announcement, setAnnouncement] = useState(defaultValues)
  const [limitsState, setLimitsState] = useState({
    title: {
      limit: ANNOUNCEMENT_FIELDS_MAX_LENGTH.TITLE,
      current: 0,
      exceeded: false
    },
    message: {
      limit: ANNOUNCEMENT_FIELDS_MAX_LENGTH.MESSAGE,
      current: 0,
      exceeded: false
    },
    ctaText: {
      limit: ANNOUNCEMENT_FIELDS_MAX_LENGTH.CTA_TEXT,
      current: 0,
      exceeded: false
    }
  })

  const [keyModifer, setKeyModifier] = useState(0)

  useEffect(() => {
    if (prefill) {
      setAnnouncement({ ...defaultValues, ...prefill })
      setKeyModifier(keyModifer + 1)
    }
  }, [prefill])

  useEffect(() => {
    // get just the plain text from message
    const announcementClone = { ...announcement }
    const throwaway = document.createElement('div')
    throwaway.innerHTML = announcementClone.message
    announcementClone.message = throwaway.innerText
    throwaway.remove()
    // Check if any fields exceed the limit
    setLimitsState((old) => ({
      ...old,
      title: {
        ...old.title,
        current: announcementClone.title.length,
        exceeded: announcementClone.title.length > old.title.limit
      },
      message: {
        ...old.message,
        current: announcementClone.message.length,
        exceeded: announcementClone.message.length > old.message.limit
      },
      ctaText: {
        ...old.ctaText,
        current: announcementClone.cta.text.length,
        exceeded: announcementClone.cta.text.length > old.ctaText.limit
      }
    }))
  }, [announcement])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError({
      state: false,
      message: ''
    })
    const { cta, ...data } = announcement

    try {
      // Validation
      const { error: validationError, message } = postAnnouncementValidation(
        limitsState,
        cta
      )
      if (validationError) {
        throw new Error(message)
      }

      // Assemble request data
      const requestData =
        cta.text === '' && cta.url === '' ? data : announcement
      requestData.message = sanitizeText(requestData.message)

      if (edit) {
        // Handle Edit here
        const response = await editAnnouncement({
          token: localStorage.getItem('authToken'),
          data: requestData
        })
        if (!response || response.status !== 200) {
          throw new Error('Error updating announcement.')
        }
      } else {
        // Handle Post here
        const response = await postAnnouncement({
          token: localStorage.getItem('authToken'),
          data: requestData
        })
        if (!response || response.status !== 200) {
          throw new Error('Error posting announcement.')
        }
      }

      toast.success(`Announcement ${edit ? 'updated' : 'posted'} successfully.`)
      setAnnouncement(defaultValues)
      setKeyModifier(keyModifer + 1)
      if (closeModal) {
        closeModal(false)
      }
    } catch (error) {
      setError({
        state: true,
        message: error.message || 'An unexpected error occurred.'
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <form
      className="w-full p-2 bg-neutral-100 dark:bg-neutral-700"
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <div className="flex w-full flex-col gap-4">
        <h2 className="">Send a New Announcement</h2>
        <div className="flex flex-col">
          <label className="dark:text-neutral-100">
            Title <span className="text-red-500">*</span>
            <span className="ml-2 text-xs">
              <span
                className={limitsState.title.exceeded ? 'text-red-500' : ''}
              >
                {limitsState.title.current} / {limitsState.title.limit}
              </span>
            </span>
          </label>
          <input
            value={announcement.title}
            placeholder="Announcement Title"
            onChange={(e) =>
              setAnnouncement((old) => ({
                ...old,
                title: e.target.value
              }))
            }
            className="input"
            required
            maxLength={limitsState.title.limit}
          />
        </div>
        <div className="flex flex-col">
          <label className="dark:text-neutral-100">
            Message <span className="text-red-500">*</span>
            <span className="ml-2 text-xs">
              <span
                className={limitsState.message.exceeded ? 'text-red-500' : ''}
              >
                {limitsState.message.current} / {limitsState.message.limit}{' '}
                {limitsState.message.exceeded
                  ? 'Your message exceeds the limit.'
                  : ''}
              </span>
            </span>
          </label>
          <div className="bg-white dark:bg-neutral-800">
            <ReactQuillEditor
              key={keyModifer}
              initialHtml={announcement.message}
              onChange={(html) =>
                setAnnouncement((old) => ({ ...old, message: html }))
              }
              maxLength={ANNOUNCEMENT_FIELDS_MAX_LENGTH.message}
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex flex-col w-full">
            <label className="dark:text-neutral-100">CTA URL</label>
            <input
              value={announcement.cta.url}
              placeholder="CTA URL"
              type="url"
              onChange={(e) =>
                setAnnouncement((old) => ({
                  ...old,
                  cta: { ...old.cta, url: e.target.value }
                }))
              }
              className="input"
            />
          </div>
          <div className="flex flex-col w-full">
            <label className="dark:text-neutral-100">
              CTA Text{' '}
              {announcement.cta.url.length > 0 && (
                <span className="text-red-500">*</span>
              )}
              <span className="ml-2 text-xs">
                <span
                  className={limitsState.ctaText.exceeded ? 'text-red-500' : ''}
                >
                  {limitsState.ctaText.current} / {limitsState.ctaText.limit}
                </span>
              </span>
            </label>
            <input
              value={announcement.cta.text}
              placeholder="CTA Text"
              onChange={(e) =>
                setAnnouncement((old) => ({
                  ...old,
                  cta: { ...old.cta, text: e.target.value }
                }))
              }
              className="input"
              maxLength={limitsState.ctaText.limit}
              disabled={!announcement.cta.url.length > 0}
              required={announcement.cta.url.length > 0}
            />
          </div>
        </div>

        {!edit && (
          <label
            className={`cursor-pointer flex w-fit items-center justify-between px-3 py-2 gap-1.5 rounded border transition-all ${
              announcement.sendNotifications
                ? 'bg-green-500/15 hover:bg-green-500/25 border-green-500/50'
                : 'bg-red-500/15 hover:bg-red-500/25 border-red-500/50'
            }`}
          >
            <p className="select-none">Send Omnichannel Notifications</p>
            <input
              type="checkbox"
              checked={announcement.sendNotifications}
              onChange={(e) =>
                setAnnouncement((old) => ({
                  ...old,
                  sendNotifications: e.target.checked
                }))
              }
              className="hidden"
            />
            <p className="sr-only">
              {announcement.sendNotifications ? `on` : `off`}
            </p>
            <FontAwesomeIcon
              icon={
                announcement.sendNotifications ? faCircleCheck : faCircleXmark
              }
              className={`${
                announcement.sendNotifications
                  ? 'text-green-500'
                  : 'text-red-500'
              }`}
            />
          </label>
        )}
        {error.state && (
          <div className="w-fit p-2 rounded border border-orange-500 bg-orange-200 dark:bg-orange-900">
            <p>
              <FontAwesomeIcon icon={faExclamationTriangle} /> {error.message}
            </p>
          </div>
        )}
        <input
          type="submit"
          className="primary-button w-fit"
          value={loading ? 'Submitting...' : 'Submit'}
        />
      </div>
    </form>
  )
}

PostAnnouncementForm.propTypes = {
  edit: PropTypes.bool,
  closeModal: PropTypes.func,
  prefill: PropTypes.object
}

export default PostAnnouncementForm
