import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import { deletePrivateMessage, deleteTrade } from '@moal/api'
import { toast } from 'react-toastify'
import { DELETE_MESSAGE } from '../../../constants/actions/messageListActions'
import { CATEGORIES } from '../../../constants/analytics'
import { MODERATORS, TRADES } from '../../../constants/chatRooms'
import { AnalyticsContext } from '../../../context/analyticsContext'
import { AuthenticationContext } from '../../../context/authenticationContext'
import { deletePublicMessage } from '../../../utils/APIs/publicMessages'
import Modal from '../../Utilities/Modal'
import Message from '../Message'
import PrivateMessage from '../PrivateMessage'
import Trade from '../Trade'

const DeleteModal = ({ open, setOpen, message, channel, dispatch }) => {
  const { pushEvent } = useContext(AnalyticsContext)
  const { user } = useContext(AuthenticationContext)

  const handleDelete = () => {
    if (channel === TRADES) {
      handleDeleteTrade(message._id)
    } else if (channel === MODERATORS) {
      handleDeletePrivateMessage(message._id)
    } else {
      handleDeletPublicMessage(message._id)
    }
    setOpen(false)
  }

  const handleDeleteTrade = async (id) => {
    const response = await deleteTrade({ id })

    if (response && response.status === 200) {
      if (dispatch) {
        dispatch({ type: DELETE_MESSAGE, payload: { id } })
      }
    }
    toast.success('Trade deleted.')
  }

  const handleDeletePrivateMessage = async (id) => {
    const response = await deletePrivateMessage({
      token: localStorage.getItem('authToken'),
      id
    })
    if (response && response.status === 200) {
      if (dispatch) {
        dispatch({ type: DELETE_MESSAGE, payload: { id } })
      }
      toast.success('Message deleted.')
      pushEvent(CATEGORIES.PRIVATE_MESSAGE, 'DELETE', user.userService._id)
    }
  }

  const handleDeletPublicMessage = async (id) => {
    const response = await deletePublicMessage({ id })
    if (response && response.status === 200) {
      toast.success('Message deleted.')
      pushEvent(CATEGORIES.PUBLIC_MESSAGE, 'DELETE', user.userService._id)
    }
  }

  const popMessage = (message) => {
    switch (channel) {
      case MODERATORS:
        return <PrivateMessage message={message} />
      case TRADES:
        return <Trade message={message} />
      default:
        return <Message message={message} channel={channel} />
    }
  }

  return (
    <Modal
      title="Delete Message / Trade"
      open={open}
      setOpen={setOpen}
      confirmFunction={handleDelete}
      size="md"
    >
      {popMessage(message)}
    </Modal>
  )
}

DeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired,
  channel: PropTypes.string.isRequired
}

export default DeleteModal
