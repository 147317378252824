import {
  getPrivateMessagesUsers,
  PRIVATE_MESSAGE_EVENTS,
  updatePrivateMessageUser
} from '@moal/api'
import React, { useContext, useEffect, useReducer } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  allUsersReducer,
  initialState
} from '../../../../reducers/allUsersReducer'
import { ErrorRow, LoadedAllRow, LoadingRow, UserRow } from '../UserRows'

import {
  ADD_USER,
  DELETE_USER,
  EDIT_USER,
  ERROR,
  HAS_MORE,
  LOADING,
  SET_USERS
} from '../../../../constants/actions/allUsersActions'
import { SocketContext } from '../../../../context/socketContext'

const UsersAwaitingReply = ({ fetchUserCounts, handlePinnedUserClick }) => {
  const { privateMessagesSocket } = useContext(SocketContext)
  const [state, dispatch] = useReducer(allUsersReducer, initialState)

  useEffect(() => {
    loadUsers()
  }, [])

  const loadUsers = async (reset = false) => {
    const response = await getPrivateMessagesUsers({
      token: localStorage.getItem('authToken'),
      loadMore:
        state.users.length > 0 && !reset
          ? `${state.users[state.users.length - 1].lowerName}`
          : '',
      awaitingReply: true
    })
    if (response && response.status === 200) {
      fetchUserCounts()
      if (response.data.length > 0) {
        if (reset) {
          dispatch({ type: SET_USERS, payload: response.data })
        } else {
          dispatch({
            type: SET_USERS,
            payload: [...state.users, ...response.data]
          })
        }
      } else {
        dispatch({ type: HAS_MORE, payload: false })
      }
    } else {
      dispatch({ type: ERROR, payload: true })
    }
    dispatch({ type: LOADING, payload: false })
  }

  useEffect(() => {
    if (privateMessagesSocket) {
      privateMessagesSocket.on(
        PRIVATE_MESSAGE_EVENTS.POST,
        handleNewPrivateMessage
      )
    }
    return () => {
      if (privateMessagesSocket) {
        privateMessagesSocket.off(
          PRIVATE_MESSAGE_EVENTS.POST,
          handleNewPrivateMessage
        )
      }
    }
  }, [privateMessagesSocket, state])

  const handleNewPrivateMessage = (data) => {
    fetchUserCounts()
    if (data.user && !data.toUser) {
      updateUserList(data.user)
    }
    if (data.toUser) {
      updateUserList(data.toUser)
    }
  }

  const updateUserList = (user) => {
    if (state.users.length === 0) {
      dispatch({ type: ADD_USER, payload: user })
    } else if (!state.users.find((us) => us._id === user._id)) {
      dispatch({ type: ADD_USER, payload: user })
    } else if (state.users.find((us) => us._id === user._id)) {
      dispatch({ type: EDIT_USER, payload: user })
    }
  }

  const handleDelete = async (userId) => {
    // post to update user to mark as replied to
    const response = await updatePrivateMessageUser({
      token: localStorage.getItem('authToken'),
      id: userId,
      data: {
        awaitingReply: false
      }
    })
    if (response && response.status === 200) {
      fetchUserCounts()
      dispatch({ type: DELETE_USER, payload: userId })
    }
  }

  const togglePinnedUser = (pmUserId) => {
    const user = state.users.find((user) => user._id === pmUserId)
    handlePinnedUserClick(user, () => {
      loadUsers(true)
    })
  }

  if (state.loading) {
    return <LoadingRow />
  }
  if (state.error) {
    return <ErrorRow />
  }

  return (
    <div className="flex flex-col overflow-y-hidden w-full h-full">
      <div id="userList" className="overflow-y-auto w-full h-full ">
        <InfiniteScroll
          dataLength={state.users.length}
          next={loadUsers}
          className="flex flex-col overflow-y-auto gap-1 w-full h-full"
          scrollableTarget="userList"
          loader={<LoadingRow />}
          endMessage={<LoadedAllRow />}
          hasMore={state.hasMore}
        >
          {state.users.map((user) => {
            return (
              <UserRow
                key={user._id}
                user={user}
                togglePinned={togglePinnedUser}
                removeFromList={handleDelete}
              />
            )
          })}
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default UsersAwaitingReply
