import { getPrivateMessageHistoricalCounts } from '@moal/api'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip
} from 'chart.js'
import 'chartjs-adapter-moment'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import DateRangePicker from '../DateRangePicker'
import styles from '../Stats.module.css'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale
)

const PrivateMessagesPerDay = () => {
  const [privMessages, setPrivMessages] = useState({
    toMods: [],
    toUsers: []
  })
  const [labels, setLabels] = useState([])
  const [data, setData] = useState(null)
  const [startDate, setStartDate] = useState(
    moment().subtract(30, 'days').toDate()
  )
  const [endDate, setEndDate] = useState(moment().toDate())
  const [scaleType, setScaleType] = useState('timeseries')

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      tooltip: {
        titleFont: {
          size: 18
        },
        bodyFont: {
          size: 24
        },
        padding: 12
      }
    },
    scales: {
      x: {
        stacked: true,
        type: scaleType,
        time: {
          unit: 'day'
        },
        ticks: {
          minRotation: 45,
          maxRotation: 45
        }
      },
      y: {
        stacked: true,
        ticks: {
          stepSize: 20
        }
      }
    }
  }

  useEffect(() => {
    loadPrivMessages()
  }, [startDate, endDate])

  useEffect(() => {
    if (privMessages.toMods.length > 0 || privMessages.toUsers.length > 0) {
      handleLabels()
    }
  }, [privMessages])

  useEffect(() => {
    if (labels.length > 0) {
      handleData()
    }
  }, [labels, startDate, endDate])

  const loadPrivMessages = async () => {
    const response = await getPrivateMessageHistoricalCounts({
      token: localStorage.getItem('authToken'),
      start: moment(startDate).toISOString(),
      end: moment(endDate).toISOString()
    })
    if (response && response.status === 200) {
      setPrivMessages(response.data)
    }
  }

  const handleLabels = () => {
    const newLabels = privMessages.toMods.map((each) => each._id)
    setLabels(newLabels)
  }

  const handleData = () => {
    setData({
      labels,
      datasets: [
        {
          label: 'Private To User',
          data: privMessages.toUsers.map((each) => ({
            x: each._id,
            y: each.count
          })),
          backgroundColor: 'rgba(0, 126, 204, 1)',
          pointStyle: false
        },
        {
          label: 'Private To Mod',
          data: privMessages.toMods.map((each) => ({
            x: each._id,
            y: each.count
          })),
          backgroundColor: 'rgba(219, 0, 88, 1)',
          pointStyle: false
        }
      ]
    })
  }

  if (labels.length <= 0 || data === null) {
    return <div>Loading...</div>
  }

  return (
    <div className={styles.wrapper}>
      <h2>Private Messages Per Day</h2>
      <Bar options={options} data={data} className="p-2" />
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        scaleType={scaleType}
        setScaleType={setScaleType}
      />
    </div>
  )
}

export default PrivateMessagesPerDay
