import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { SiteConfigContext } from '../../context/siteConfigContext'
import { setPageTitle } from '../../utils/utilities'

const DownDetector = () => {
  setPageTitle('Down Detector')
  const navigate = useNavigate()
  const { isError } = useContext(SiteConfigContext)

  useEffect(() => {
    if (!isError) {
      navigate('/', { replace: true })
    }
  }, [isError])

  return (
    <main className="ios-safari-hack bg-custom-gradient flex flex-col h-screen overflow-y-auto">
      <div className="my-auto">
        <div className="container sm:rounded-xl bg-black/50 w-full mx-auto p-4 md:p-20">
          <div className="flex flex-col justify-between items-center gap-4">
            <h1 className="text-7xl font-bold border-b pb-4">Be Right Back</h1>
            <p className="w-full sm:w-80">
              Oops! It looks like we are experiencing an outage. Our team of
              developers is on the case and working hard to restore it.
            </p>
            <p className="w-full sm:w-80">
              Thanks for your patience and understanding. We apologize for any
              inconvenience this may have caused.
            </p>
          </div>
        </div>
      </div>
    </main>
  )
}

export default DownDetector
