import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  deleteCommandBotMessage,
  getCommandBotServices,
  postCommandBotMessage,
  postCommandBotService
} from '@moal/api'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { toast } from 'react-toastify'
import { getBot } from '../../../utils/APIs/commandBot'
import { setPageTitle } from '../../../utils/utilities'

const TRIGGER_MAX_LENGTH = 15
const MESSAGE_MAX_LENGTH = 500

const CommandBot = () => {
  setPageTitle('Command Bot')
  const [bot, setBot] = useState()
  const [services, setServices] = useState([])

  const keys = [
    {
      service: 'SHARED',
      label:
        'The default / global command to be used in public or private messages'
    },
    {
      service: process.env.REACT_APP_PRODUCT_CODE,
      label: 'Gets priority over SHARED for public messages on this site only'
    },
    {
      service: 'PRIVATE',
      label: 'Gets priority over SHARED in private messages globally'
    }
  ]

  useEffect(() => {
    setUp()
  }, [])

  const setUp = async () => {
    const bResponse = await getBot()
    if (bResponse && bResponse.status === 200) {
      setBot(bResponse.data)
    }

    const response = await getCommandBotServices({
      itemNumber: process.env.REACT_APP_PRODUCT_CODE
    })
    if (response && response.status === 200) {
      setServices(response.data)
    }
  }

  return (
    <div className="flex flex-col gap-4">
      {bot ? (
        <>
          {keys.map((key) => {
            return services.find((e) => e.itemNumber === key.service) ? (
              <Service
                key={key.service}
                service={services.find((e) => e.itemNumber === key.service)}
                label={key.label}
              />
            ) : (
              <InitService key={key.service} itemNumber={key.service} />
            )
          })}
        </>
      ) : (
        <div>Something went wrong getting bot</div>
      )}
    </div>
  )
}

const Service = ({ service, label }) => {
  const [messages, setMessages] = useState(service.messages)

  const handleOnAdd = (message) => {
    const addTo = [message, ...messages]
    setMessages(addTo)
  }

  const handleOnDelete = (id) => {
    const filtered = messages.filter((each) => each._id !== id)
    setMessages(filtered)
  }

  return (
    <div className="flex flex-col gap-2 border dark:border-neutral-700 p-4 rounded shadow">
      <div className="flex flex-col gap-2 shrink">
        <h2>{service.itemNumber} MESSAGES</h2>
        <p>{label}</p>
      </div>
      <div className="flex flex-col gap-2 shrink">
        <h2>Add {service.itemNumber} Message</h2>
        <AddMessageForm
          itemNumber={service.itemNumber}
          messages={messages}
          handleOnAdd={handleOnAdd}
        />
      </div>
      <div className="flex flex-col gap-2 shrink">
        <h2>Existing Messages</h2>
        <div className="relative overflow-x-auto">
          <div className="flex flex-col gap-1">
            <div className="flex justify-between gap-1 font-bold text-neutral-700 uppercase bg-neutral-100 dark:bg-neutral-700 dark:text-neutral-400">
              <p className="p-3 text-xs">Trigger</p>
              <p className="p-3 text-xs">Message</p>
              <p className="p-3 text-xs">Delete?</p>
            </div>
            <div className="text-neutral-500 dark:text-neutral-400 bg-white dark:bg-neutral-800 dark:border-neutral-700">
              {messages.map((msg) => {
                return (
                  <Message
                    key={msg._id}
                    itemNumber={service.itemNumber}
                    message={msg}
                    handleOnDelete={handleOnDelete}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Message = ({ message, handleOnDelete }) => {
  return (
    <div
      key={message._id}
      className="flex justify-start gap-1 border-b last:border-0 border-neutral-100 dark:border-neutral-700 max-w-full"
    >
      <p className="p-3 text-sm break-words w-48 max-w-auto">
        {message.trigger}
      </p>
      <p className="p-3 text-sm break-words w-full max-w-auto overflow-x-auto">
        {message.message}
      </p>
      <DeleteMessageForm message={message} handleOnDelete={handleOnDelete} />
    </div>
  )
}

const InitService = ({ itemNumber }) => {
  const handleInit = async () => {
    const response = await postCommandBotService({
      token: localStorage.getItem('authToken'),
      itemNumber
    })
    if (response && response.status === 201) {
      window.location.reload()
    }
  }

  return (
    <div className="flex flex-col gap-2">
      <button
        onClick={handleInit}
        aria-label="Submit bot trigger and message"
        className="primary-button relative inline-flex items-center gap-x-1.5 rounded-r px-3 py-2 flex-shrink-0 lg:mt-8"
      >
        Create {itemNumber} Service
      </button>
    </div>
  )
}

const AddMessageForm = ({ itemNumber, messages, handleOnAdd }) => {
  const [trigger, setTrigger] = useState('')
  const [message, setMessage] = useState('')
  const toastError =
    'Error adding command. Please verify that the Trigger and Message are valid.'

  const handleTriggerChange = (value) => {
    value = value.replace(/[^A-Za-z1-9]/gi, '')
    setTrigger(value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const match = messages.find((each) => `${each.trigger}` === trigger)
    if (match) {
      toast.error(toastError)
    } else {
      const response = await postCommandBotMessage({
        token: localStorage.getItem('authToken'),
        itemNumber,
        trigger,
        message
      })
      if (response && response.status === 201) {
        handleOnAdd(response.data)
        setTrigger('')
        setMessage('')
        toast.success('Bot command added.')
      } else {
        toast.error(toastError)
      }
    }
  }

  return (
    <div className="mb-auto">
      <form
        aria-label="Add bot message"
        onSubmit={handleSubmit}
        autoComplete="off"
        className="relative w-full flex flex-col lg:flex-row flex-grow items-start focus-within:z-10 gap-2"
      >
        <div className="h-fit w-full">
          <h4 className="mb-1">
            Trigger
            <span className="ml-2 text-xs">
              <span
                className={
                  trigger.length === TRIGGER_MAX_LENGTH
                    ? 'text-red-500'
                    : 'text-neutral-500'
                }
              >
                ({trigger.length} / {TRIGGER_MAX_LENGTH})
              </span>
            </span>
          </h4>
          <input
            aria-label="Input bot trigger"
            value={trigger}
            onChange={(e) => handleTriggerChange(e.target.value)}
            className="input w-full"
            placeholder="Enter a trigger..."
            maxLength={TRIGGER_MAX_LENGTH}
          />
        </div>
        <div className="h-fit w-full">
          <h4 className="mb-1">
            Message
            <span className="ml-2 text-xs">
              <span
                className={
                  message.length === MESSAGE_MAX_LENGTH
                    ? 'text-red-500'
                    : 'text-neutral-500'
                }
              >
                ({message.length} / {MESSAGE_MAX_LENGTH})
              </span>
            </span>
          </h4>
          <TextareaAutosize
            aria-label="Input bot message"
            id="input"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="input block w-full flex-auto h-[2.6rem] min-h-[2.6rem] "
            placeholder="Enter a message..."
            minRows={1}
            maxRows={4}
            autoComplete="off"
            maxLength={MESSAGE_MAX_LENGTH}
          />
        </div>
        <button
          type="submit"
          aria-label="Submit bot trigger and message"
          className="primary-button relative inline-flex items-center gap-x-1.5 rounded-r px-3 py-2 flex-shrink-0 lg:mt-8"
        >
          Submit
        </button>
      </form>
    </div>
  )
}

AddMessageForm.propTypes = {
  messages: PropTypes.array,
  handleOnAdd: PropTypes.func.isRequired
}

const DeleteMessageForm = ({ message, handleOnDelete }) => {
  const [confirm, setConfirm] = useState(false)

  const handleOnClick = (value) => {
    setConfirm(value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const response = await deleteCommandBotMessage({
      token: localStorage.getItem('authToken'),
      id: message._id
    })
    if (response && response.status === 200) {
      setConfirm(false)
      handleOnDelete(message._id)
      toast.success('Bot command deleted.')
    }
  }

  if (confirm) {
    return (
      <div className="flex gap-1 items-start justify-end p-3 ml-auto">
        <button
          className="flex justify-around text-white rounded-full items-center bg-green-500 hover:bg-green-600 active:bg-green-700 h-6 w-6"
          onClick={handleSubmit}
        >
          <FontAwesomeIcon className="h-3.5 w-3.5" icon={faCheck} />
        </button>
        <button
          className="flex justify-around text-white rounded-full items-center bg-red-500 hover:bg-red-600 active:bg-red-700 h-6 w-6"
          onClick={() => handleOnClick(false)}
        >
          <FontAwesomeIcon className="h-3.5 w-3.5" icon={faTimes} />
        </button>
      </div>
    )
  }

  return (
    <div className="flex gap-1 items-start justify-end p-3 ml-auto">
      <button
        className="flex justify-around text-white rounded-full items-center bg-red-500 hover:bg-red-600 active:bg-red-700 h-6 w-6"
        onClick={() => handleOnClick(true)}
      >
        <FontAwesomeIcon className="h-3.5 w-3.5" icon={faTimes} />
      </button>
    </div>
  )
}

DeleteMessageForm.propTypes = {
  message: PropTypes.object.isRequired,
  handleOnDelete: PropTypes.func.isRequired
}

export default CommandBot
