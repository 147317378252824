import React, { useState, useContext, useEffect } from 'react'
import { SocketContext } from '../../../context/socketContext'
import { TRADE_EVENTS } from '@moal/api'
import { MEMBERS, TRADES } from '../../../constants/chatRooms'
import ChatSectionNav from '../ChatSectionNav'
import MessageList from '../../Messages/MessageList'
import TradeAlert from '../../Utilities/TradeAlert'
import PinnedMessage from '../../Utilities/PinnedMessage'
import { useSearchParams } from 'react-router-dom'
import * as SETTINGS from '../../../constants/settings'
import Poll from '../../Utilities/Poll'
import TradeSearch from '../../Forms/TradeSearch'
import { SiteConfigContext } from '../../../context/siteConfigContext'

const ChatSection = () => {
  const { updateSetting } = useContext(SiteConfigContext)
  const {
    setReplyTo,
    activePoll,
    [SETTINGS.PINNED_MESSAGE.key]: pinnedMessage
  } = useContext(SiteConfigContext)
  const { tradesSocket } = useContext(SocketContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const [channel, setChannel] = useState(searchParams.get('channel') || MEMBERS)
  const [showAlert, setShowAlert] = useState(false)
  const [tradeData, setTradeData] = useState()
  const [search, setSearch] = useState(null)
  const [showPinnedMessage, setShowPinnedMessage] = useState(true)

  useEffect(() => {
    if (tradesSocket) {
      tradesSocket.on(TRADE_EVENTS.POST, handlePostTrade)
      tradesSocket.on(TRADE_EVENTS.PUT, handlePostTrade)
    }
    return () => {
      if (tradesSocket) {
        tradesSocket.off(TRADE_EVENTS.POST, handlePostTrade)
        tradesSocket.off(TRADE_EVENTS.PUT, handlePostTrade)
      }
    }
  }, [tradesSocket, channel])

  const handleSetChannel = (selected) => {
    if (channel !== selected) {
      setChannel(selected)
      setSearchParams({ channel: selected })
      setSearch(null)
    }

    if (selected === TRADES) {
      setShowAlert(false)
    }

    setReplyTo(null)
  }

  const unPinMessage = async () => {
    await updateSetting(SETTINGS.PINNED_MESSAGE, null)
  }

  useEffect(() => {
    setShowPinnedMessage(true)
  }, [pinnedMessage])

  const handleSearch = (value) => {
    setSearch(value)
  }

  const handlePostTrade = (data) => {
    if (data.itemNumber === process.env.REACT_APP_PRODUCT_CODE) {
      if (channel !== TRADES) {
        setShowAlert(true)
        setTradeData(data)
      }
    }
  }

  return (
    <div className="relative flex w-full max-w-full flex-col overflow-auto bg-white dark:bg-neutral-900 md:w-64 lg:w-96">
      <ChatSectionNav active={channel} handleSetChannel={handleSetChannel} />
      {showAlert && (
        <div className="h-auto grow shadow">
          <TradeAlert message={tradeData} toggleAlert={setShowAlert} />
        </div>
      )}
      {!showAlert &&
        activePoll &&
        !activePoll.isArchived &&
        channel === MEMBERS && (
          <div className="h-auto grow shadow">
            <Poll {...activePoll} />
          </div>
        )}
      {!showAlert &&
        !activePoll &&
        showPinnedMessage &&
        pinnedMessage &&
        channel === MEMBERS && (
          <div className="h-auto grow shadow">
            <PinnedMessage
              message={pinnedMessage}
              unPin={unPinMessage}
              toggle={setShowPinnedMessage}
            />
          </div>
        )}
      {channel === TRADES && (
        <div className="sticky bottom-0 shrink">
          <TradeSearch handleSearch={handleSearch} />
        </div>
      )}
      <MessageList channel={channel} search={search} />
    </div>
  )
}

export default ChatSection
