import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip
} from 'chart.js'
import 'chartjs-adapter-moment'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import {
  getUserCount,
  getUserRegistrationCount
} from '../../../../utils/APIs/users'
import DateRangePicker from '../DateRangePicker'
import styles from '../Stats.module.css'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale,
  TimeSeriesScale
)

const Users = () => {
  const [registrations, setRegistrations] = useState([])
  const [totalUsers, setTotalUser] = useState(0)
  const [userPerDay, setUsersPerDay] = useState([])
  const [labels, setLabels] = useState([])
  const [data, setData] = useState(null)
  const [startDate, setStartDate] = useState(
    moment().subtract(30, 'days').toDate()
  )
  const [endDate, setEndDate] = useState(moment().toDate())
  const [scaleType, setScaleType] = useState('timeseries')

  const options = {
    responsive: true,
    elements: {
      line: {
        tension: 0.2 // smooth lines
      }
    },
    plugins: {
      legend: {
        position: 'top'
      },
      tooltip: {
        titleFont: {
          size: 18
        },
        bodyFont: {
          size: 24
        },
        padding: 12
      }
    },
    scales: {
      x: {
        type: scaleType,
        time: {
          unit: 'day'
        },
        ticks: {
          minRotation: 45,
          maxRotation: 45
        }
      },
      y: {
        ticks: {
          stepSize: 100
        }
      }
    }
  }

  useEffect(() => {
    loadData()
  }, [startDate, endDate])

  useEffect(() => {
    if (registrations.length > 0 && totalUsers > 0) {
      handleLabels()
      handleUsersPerDay()
    }
  }, [registrations, totalUsers])

  useEffect(() => {
    if (labels.length > 0) {
      handleData()
    }
  }, [labels, startDate, endDate])

  const loadData = async () => {
    loadRegistrations()
    getTotal()
  }

  const loadRegistrations = async () => {
    const response = await getUserRegistrationCount(
      moment(startDate).toISOString(),
      moment(endDate).toISOString()
    )
    if (response && response.status === 200) {
      setRegistrations(response.data)
    }
  }

  const getTotal = async () => {
    const response = await getUserCount()
    if (response && response.status === 200) {
      setTotalUser(response.data.count)
    }
  }

  const handleLabels = () => {
    const newLabels = registrations.map((each) => each._id)
    setLabels(newLabels)
  }

  const handleUsersPerDay = () => {
    let currentCount = totalUsers
    const dailies = registrations.map((each) => {
      const dataPoint = {
        _id: each._id,
        count: currentCount
      }
      currentCount = currentCount - each.count
      return dataPoint
    })
    setUsersPerDay(dailies)
  }

  const handleData = () => {
    setData({
      labels,
      datasets: [
        {
          label: 'New Users',
          data: registrations.map((each) => ({
            x: each._id,
            y: Math.round(each.count)
          })),
          borderColor: 'rgba(219, 0, 88, 1)',
          backgroundColor: 'rgba(219, 0, 88, 1)',
          pointStyle: false,
          fill: true,
          type: 'line'
        },
        {
          label: 'Total Users',
          data: userPerDay.map((each) => ({
            x: each._id,
            y: each.count
          })),
          backgroundColor: 'rgba(0, 126, 204, 1)',
          pointStyle: false,
          fill: true,
          type: 'line'
        }
      ]
    })
  }

  if (labels.length <= 0 || data === null) {
    return <div>Loading...</div>
  }

  return (
    <div className={styles.wrapper}>
      <h2> Users Per Day </h2>
      <Line options={options} data={data} className="p-2" />
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        scaleType={scaleType}
        setScaleType={setScaleType}
      />
    </div>
  )
}

export default Users
