import { faExternalLink } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PRODUCT_TYPES } from '@moal/api'
import React, { Fragment, useContext } from 'react'
import { Link } from 'react-router-dom'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import { removeSlashFromUrl } from '../../../utils/utilities'
import Copywrite from '../../Utilities/Copywrite'
import styles from './Footer.module.css'

const Footer = () => {
  const { freeEnabled, productsService } = useContext(SiteConfigContext)

  const hubURL = removeSlashFromUrl(
    productsService[PRODUCT_TYPES.HUB].urls.website
  )

  return (
    <div className="flex flex-col gap-1 xl:gap-2 xl:flex-row text-xs mx-auto justify-center items-center">
      <Copywrite />
      {freeEnabled && (
        <Fragment>
          <Link
            to={`${hubURL}/privacy-policy`}
            target="_blank"
            className={styles.footerItem}
          >
            Privacy Policy
            <FontAwesomeIcon
              icon={faExternalLink}
              className="w-2 h-2 text-black text-opacity-50 dark:text-white dark:text-opacity-50"
            />
          </Link>
          <Link
            to={`${hubURL}/do-not-sell`}
            target="_blank"
            className={styles.footerItem}
          >
            Do Not Sell or Share My Personal Information
            <FontAwesomeIcon
              icon={faExternalLink}
              className="w-2 h-2 text-black text-opacity-50 dark:text-white dark:text-opacity-50"
            />
          </Link>
        </Fragment>
      )}
    </div>
  )
}

export default Footer
