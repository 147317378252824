import { getPrivateMessageThread, PRIVATE_MESSAGE_EVENTS } from '@moal/api'
import { useContext, useEffect } from 'react'
import {
  ADD_MESSAGE,
  DELETE_MESSAGE,
  EDIT_MESSAGE,
  INITIAL_LOAD,
  LOAD_MORE
} from '../constants/actions/messageListActions'
import { MODERATORS } from '../constants/chatRooms'
import { SocketContext } from '../context/socketContext'

export const usePrivateMessages = (
  user,
  userId,
  channel,
  dispatch,
  modView
) => {
  const { privateMessagesSocket } = useContext(SocketContext)

  useEffect(() => {
    if (channel === MODERATORS && privateMessagesSocket) {
      privateSockets()
    } else if (privateMessagesSocket) {
      privateSockets(false)
    }
    return () => {
      if (privateMessagesSocket) {
        privateSockets(false)
      }
    }
  }, [privateMessagesSocket, channel])

  const privateSockets = (on = true) => {
    if (on) {
      privateMessagesSocket.on(
        PRIVATE_MESSAGE_EVENTS.POST,
        handleNewPrivateMessage
      )
      privateMessagesSocket.on(
        PRIVATE_MESSAGE_EVENTS.EDIT,
        handleEditedPrivateMessage
      )
      privateMessagesSocket.on(
        PRIVATE_MESSAGE_EVENTS.DELETE,
        handleDeletedPrivateMessage
      )
    } else {
      privateMessagesSocket.off(
        PRIVATE_MESSAGE_EVENTS.POST,
        handleNewPrivateMessage
      )
      privateMessagesSocket.off(
        PRIVATE_MESSAGE_EVENTS.EDIT,
        handleEditedPrivateMessage
      )
      privateMessagesSocket.off(
        PRIVATE_MESSAGE_EVENTS.DELETE,
        handleDeletedPrivateMessage
      )
    }
  }

  const handleNewPrivateMessage = (data) => {
    if (
      renderPrivateMessage({
        loggedInUser: user.userService._id,
        modIsLookingAt: userId,
        senderUserService: data.user.userService._id,
        senderPrivateMessages: data.user._id,
        receiverPrivateMessages: data?.toUser?._id
      })
    ) {
      dispatch({ type: ADD_MESSAGE, payload: data })
    }
  }

  const handleEditedPrivateMessage = (data) => {
    {
      if (
        renderPrivateMessage({
          loggedInUser: user.userService._id,
          modIsLookingAt: userId,
          senderUserService: data.user.userService._id,
          senderPrivateMessages: data.user._id,
          receiverPrivateMessages: data?.toUser?._id
        })
      ) {
        dispatch({ type: EDIT_MESSAGE, payload: data })
      }
    }
  }

  const handleDeletedPrivateMessage = (data) => {
    dispatch({ type: DELETE_MESSAGE, payload: { id: data._id } })
  }

  const renderPrivateMessage = ({
    loggedInUser, // user service id
    modIsLookingAt, // private messages service id
    senderUserService, // user service id
    senderPrivateMessages, // private messages service id
    receiverPrivateMessages // private messages service id
  }) => {
    if (loggedInUser === senderUserService) {
      return false
    }

    if (!modView) {
      return true
    } else {
      return (
        modIsLookingAt === senderPrivateMessages || // subscriber sends message
        modIsLookingAt === receiverPrivateMessages // subscriber receives message
      )
    }
  }

  const handleGetPrivateMessages = async ({
    initialLoad,
    messages,
    userId = null,
    search = null
  }) => {
    let loadMore = ''
    if (!initialLoad && messages.length > 0) {
      loadMore = messages[messages.length - 1].postDate
    }

    const response = await getPrivateMessageThread({
      token: localStorage.getItem('authToken'),
      loadMore,
      userId,
      search
    })

    if (response && response.status === 200) {
      if (initialLoad) {
        dispatch({ type: INITIAL_LOAD, payload: response.data })
      } else {
        dispatch({ type: LOAD_MORE, payload: response.data })
      }
    }
  }

  return { handleGetPrivateMessages }
}
