import {
  getPrivateMessageUserCounts,
  updatePrivateMessageUser
} from '@moal/api'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Outlet, useParams, useSearchParams } from 'react-router-dom'
import { setPageTitle, setPageTitleCounter } from '../../../utils/utilities'
import { AllUsers, PinnedUsers, UsersAwaitingReply } from './UserLists'

const USERS_AWAITNG_REPLY = 'Users Awaiting Reply'
const ALL_USERS = 'All Users'
const PINNED_USERS = 'Pinned Users'

const PrivateMessages = () => {
  setPageTitle('Private Messages')
  const [searchParams] = useSearchParams()
  const searchId = searchParams.get('id') || null

  const [active, setActive] = useState(
    searchId ? ALL_USERS : USERS_AWAITNG_REPLY
  )
  const { userId } = useParams()
  const [userCounts, setUserCounts] = useState({
    total: 0,
    awaitingReply: 0,
    pinned: 0
  })

  useEffect(() => {
    fetchUserCounts()
  }, [])

  const fetchUserCounts = () => {
    getPrivateMessageUserCounts({
      token: localStorage.getItem('authToken')
    }).then((res) => {
      setUserCounts(res.data)
    })
  }

  useEffect(() => {
    setPageTitleCounter(userCounts.awaitingReply, 'Private Messages')
  }, [userCounts])

  const onFocus = () => {
    setPageTitle('Private Messages')
  }

  const onBlur = () => {
    setPageTitleCounter(userCounts.awaitingReply, 'Private Messages')
  }

  useEffect(() => {
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)
    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  }, [userCounts])

  const handlePinnedUserClick = async (pmUser, callback) => {
    const response = await updatePrivateMessageUser({
      token: localStorage.getItem('authToken'),
      id: pmUser._id,
      data: { pinned: !pmUser.pinned }
    })
    if (response && response.status === 200) {
      callback()
    }
  }

  return (
    <div className="flex h-full w-full flex-row overflow-hidden gap-2">
      <div
        className={`flex-col overflow-hidden w-full ${
          userId ? 'lg:flex hidden' : 'flex'
        }`}
      >
        <div className="h-auto">
          <PrivateMessagesNav
            active={active}
            handleClick={setActive}
            userCounts={userCounts}
          />
        </div>
        <div className="overflow-hidden h-full">
          {active === USERS_AWAITNG_REPLY && (
            <UsersAwaitingReply
              fetchUserCounts={fetchUserCounts}
              handlePinnedUserClick={handlePinnedUserClick}
            />
          )}
          {active === ALL_USERS && (
            <AllUsers
              fetchUserCounts={fetchUserCounts}
              handlePinnedUserClick={handlePinnedUserClick}
              searchId={searchId}
            />
          )}
          {active === PINNED_USERS && (
            <PinnedUsers
              fetchUserCounts={fetchUserCounts}
              handlePinnedUserClick={handlePinnedUserClick}
            />
          )}
        </div>
      </div>

      <div className={`flex flex-col w-full ${userId ? 'flex' : 'hidden'}`}>
        <Outlet />
      </div>
    </div>
  )
}

const PrivateMessagesNav = ({ active, handleClick, userCounts }) => {
  return (
    <div className="overflow-hidden flex h-full w-full flex-col justify-between shadow">
      <ul className="flex w-full">
        <li className="w-full">
          <button
            onClick={() => handleClick(USERS_AWAITNG_REPLY)}
            className={`chat-nav-button ${
              active === USERS_AWAITNG_REPLY
                ? 'chat-nav-button-active'
                : 'chat-nav-button-inactive'
            }`}
          >
            {USERS_AWAITNG_REPLY} {userCounts.awaitingReply}
          </button>
        </li>
        <li className="w-full">
          <button
            onClick={() => handleClick(PINNED_USERS)}
            className={`chat-nav-button ${
              active === PINNED_USERS
                ? 'chat-nav-button-active'
                : 'chat-nav-button-inactive'
            }`}
          >
            {PINNED_USERS} {userCounts.pinned}
          </button>
        </li>
        <li className="w-full">
          <button
            onClick={() => handleClick(ALL_USERS)}
            className={`chat-nav-button border-l dark:border-neutral-900 ${
              active === ALL_USERS
                ? 'chat-nav-button-active'
                : 'chat-nav-button-inactive'
            }`}
          >
            {ALL_USERS} {userCounts.total}
          </button>
        </li>
      </ul>
    </div>
  )
}

PrivateMessagesNav.propTypes = {
  active: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  userCounts: PropTypes.shape({
    total: PropTypes.number.isRequired,
    awaitingReply: PropTypes.number.isRequired,
    pinned: PropTypes.number.isRequired
  }).isRequired
}

export default PrivateMessages
