import axios from 'axios'
import { API } from '../ENV'

// Portfolio
export const getPortfolio = async (id) => {
  try {
    const response = await axios.get(`${API}/portfolio/${id}`)
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

// Views
export const getViewCounts = async (type, start, end) => {
  try {
    const response = await axios.get(`${API}/views/${type}/${start}/${end}`)
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

// Font Awesome Icons
export const getCustomIcons = async (kit) => {
  try {
    const response = await axios.get(
      `${API}/serverFunctions/fontawesome/get-custom-icons/${kit}`
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}
