import axios from 'axios'
import { API } from '../ENV'

export const getBot = async () => {
  try {
    const response = await axios.get(`${API}/bot`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    })
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}
