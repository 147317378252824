import React from 'react'
import PropTypes from 'prop-types'
import { putUser } from './APIs/users'

export const colors = [
  // 'slate',
  // 'gray',
  // 'zinc',
  // 'neutral',
  // 'stone',
  'red',
  'orange',
  'amber',
  'yellow',
  'lime',
  'green',
  'emerald',
  'teal',
  'cyan',
  'sky',
  'blue',
  'indigo',
  'violet',
  'purple',
  'fuchsia',
  'pink',
  'rose'
]

export const numbers = [
  // 50,
  // 100,
  // 200,
  // 300,
  400, 500, 600, 700
  // 800
  // 900
  // 950
]

function getRandomInt(max) {
  return Math.floor(Math.random() * max)
}

export const randomColor = () => {
  const color = colors[getRandomInt(colors.length)]
  const number = numbers[getRandomInt(numbers.length)]

  return `${color}-${number}`
}

export const replaceDefaultColor = async (user, updateUser) => {
  if (user && user.userService.profile.color === 'neutral-500') {
    const response = await putUser(
      user._id,
      'userService.profile.color',
      randomColor()
    )
    if (response && response.status === 200) {
      updateUser(response.data)
    }
  }
}

export const ColorPicker = ({ handleSelect, shades = numbers }) => {
  return (
    <div className="flex bg-white dark:bg-neutral-900">
      <div className="grid grid-cols-8 md:grid-cols-12 p-2 gap-1 rounded border dark:border-neutral-700">
        {colors.map((c) => {
          return numbers
            .filter((n) => shades.includes(n))
            .map((n) => {
              return (
                <button
                  type="button"
                  key={`${c}-${n}`}
                  className={`col-span-1 w-6 h-6 bg-${c}-${n} hover:ring-1 hover:ring-offset-2 dark:hover:ring-offset-black ring-${c}-${n}`}
                  onClick={() => handleSelect(`${c}-${n}`)}
                />
              )
            })
        })}
      </div>
    </div>
  )
}

ColorPicker.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  shades: PropTypes.array
}
