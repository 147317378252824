import { getPrivateMessagesUsers } from '@moal/api'
import React, { useEffect, useReducer } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  initialState,
  pinnedUsersReducer
} from '../../../../reducers/pinnedUsersReducer'
import { ErrorRow, LoadedAllRow, LoadingRow, UserRow } from '../UserRows'

import {
  ERROR,
  HAS_MORE,
  LOADING,
  SET_USERS
} from '../../../../constants/actions/allUsersActions'

const PinnedUsers = ({ fetchUserCounts, handlePinnedUserClick }) => {
  const [state, dispatch] = useReducer(pinnedUsersReducer, initialState)

  useEffect(() => {
    loadUsers()
  }, [])

  const loadUsers = async (reset = false, search = null) => {
    const response = await getPrivateMessagesUsers({
      token: localStorage.getItem('authToken'),
      loadMore:
        state.users.length > 0 && !reset
          ? `${state.users[state.users.length - 1].lowerName}`
          : '',
      search: search,
      pinned: true
    })
    if (response && response.status === 200) {
      fetchUserCounts()
      if (reset) {
        dispatch({ type: SET_USERS, payload: response.data })
      } else {
        dispatch({
          type: SET_USERS,
          payload: [...state.users, ...response.data]
        })
      }
      if (response.data.length === 0) {
        dispatch({ type: HAS_MORE, payload: false })
      }
    } else {
      dispatch({ type: ERROR, payload: true })
    }
    dispatch({ type: LOADING, payload: false })
  }

  const handleSearch = (value) => {
    loadUsers(true, value)
  }

  const togglePinnedUser = (pmUserId) => {
    const user = state.users.find((user) => user._id === pmUserId)
    handlePinnedUserClick(user, () => {
      loadUsers(true)
    })
  }

  if (state.loading) {
    return <LoadingRow />
  }
  if (state.error) {
    return <ErrorRow />
  }

  return (
    <div className="flex flex-col overflow-y-hidden w-full h-full">
      <input
        onChange={(e) => handleSearch(e.target.value)}
        className="input block w-full mb-1 rounded-none"
        placeholder="Search"
      />
      <div id="userList" className="overflow-y-auto w-full h-full ">
        <InfiniteScroll
          dataLength={state.users.length}
          next={loadUsers}
          className="flex flex-col overflow-y-auto gap-1 w-full h-full"
          scrollableTarget="userList"
          loader={<LoadingRow />}
          endMessage={<LoadedAllRow />}
          hasMore={state.hasMore}
        >
          {state.users.map((user) => {
            return (
              <UserRow
                key={user._id}
                user={user}
                togglePinned={togglePinnedUser}
                allUsers
              />
            )
          })}
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default PinnedUsers
