import PropTypes from 'prop-types'
import React, { useContext, useEffect } from 'react'
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation
} from 'react-router-dom'
import { AnalyticsContext } from './context/analyticsContext'
import {
  AuthenticationContext,
  AuthenticationProvider
} from './context/authenticationContext'
import {
  SiteConfigContext,
  SiteConfigProvider
} from './context/siteConfigContext'
import { SocketProvider } from './context/socketContext'
import RequireAuth from './utils/RequireAuth'
import RequireRole from './utils/RequireRole'

import * as FEATURES from './constants/features'
import * as ROLES from './constants/roles'

import {
  Chatroom,
  DownDetector,
  Headquarters,
  Initialize,
  Login,
  Moderation,
  NotFound,
  Portfolio,
  Register,
  TermsAndConditions,
  WelcomeVideo
} from './pages'

import {
  AdBuilder,
  AnnouncementHistory,
  Cache,
  ChatHistory,
  CommandBot,
  CommandBotLegacy,
  CommandCenterControls,
  Polls,
  PostAnnouncement,
  PostPMKTrade,
  PostTrade,
  PrivateMessages,
  SiteSettings,
  Stats,
  TradeHistory,
  UserDump,
  Users
} from './components/Moderation'

import { ContentWrapper } from './components/Moderation/Shared'

import {
  General,
  Memberships,
  Product
} from './components/Moderation/Configuration'

import {
  AudioHelpGuide,
  Headquarters as ConfigHeadquarters,
  Portfolio as ConfigPortfolio,
  TermsAndConditions as ConfigTermsAndConditions,
  ContentGeneral,
  Graphics,
  HeadquartersFree,
  Schedule,
  Streamers
} from './components/Moderation/Content'

import ModPollResults from './components/Moderation/Polls/Results'
import AudioVideoTroubleshooting from './pages/AudioVideoTroubleshooting'

export const DownWrapper = () => {
  const { isError } = useContext(SiteConfigContext)
  if (isError) {
    return <DownDetector />
  }

  return <Outlet />
}

export const AuthWrapper = () => {
  return (
    <RequireAuth>
      <Outlet />
    </RequireAuth>
  )
}

export const SocketWrapper = () => {
  return (
    <SocketProvider>
      <Outlet />
    </SocketProvider>
  )
}

export const RoleWrapper = ({ role }) => {
  return (
    <RequireRole role={role}>
      <Outlet />
    </RequireRole>
  )
}

RoleWrapper.propTypes = {
  role: PropTypes.string
}

const PageLoadWrapper = ({ children }) => {
  const analyticsContext = useContext(AnalyticsContext)
  const { productsService, isLoading } = useContext(SiteConfigContext)
  const { user, getSubType } = useContext(AuthenticationContext)
  const location = useLocation()

  useEffect(() => {
    if (analyticsContext.portraitIsReady) {
      const { Portrait } = window
      Portrait.dataLayer.identity.login_state = user
        ? 'logged-in'
        : 'logged-out'
      // Can stash any metadata into the page we want at this point before firing the event
      analyticsContext.updatePageMeta({})
      if (user && user.userService.email) {
        analyticsContext.updateIdentity(user.userService.email)
        if (
          !isLoading &&
          productsService &&
          Object.keys(productsService).length
        ) {
          Portrait.dataLayer.identity.account_type = getSubType(user)
        }
        Portrait.dataLayer.identity.customer_number =
          user.userService.customerNumber || 'UNKNOWN'
      }
      document.dispatchEvent(
        analyticsContext.portraitInstance.portraitPageLoaded
      )
    }
  }, [
    location,
    analyticsContext.portraitIsReady,
    user,
    productsService,
    isLoading
  ])
  return <>{children}</>
}

PageLoadWrapper.propTypes = {
  children: PropTypes.any
}

function App() {
  return (
    <SiteConfigProvider>
      <AuthenticationProvider>
        {/* eslint-disable-next-line */}
        <div className="App dark">
          <BrowserRouter>
            <PageLoadWrapper>
              <Routes>
                <Route element={<DownWrapper />}>
                  <Route element={<AuthWrapper />}>
                    <Route element={<SocketWrapper />}>
                      <Route index element={<Chatroom />} />
                      <Route
                        path="terms-and-conditions"
                        element={<TermsAndConditions />}
                      />
                      <Route path="welcome" element={<WelcomeVideo />} />
                      <Route path="register" element={<Register />} />
                      <Route path="portfolio" element={<Portfolio />} />
                      <Route path="hq" element={<Headquarters />} />
                      <Route
                        path="audio-video-troubleshooting"
                        element={<AudioVideoTroubleshooting />}
                      />
                      <Route element={<RoleWrapper role="staff" />}>
                        <Route path="mod" element={<Moderation />}>
                          <Route path="settings" element={<SiteSettings />} />
                          <Route path="post-trade" element={<PostTrade />} />
                          <Route
                            path="post-pmk-trade"
                            element={<PostPMKTrade />}
                          />
                          <Route
                            path="post-announcement"
                            element={<PostAnnouncement />}
                          />
                          <Route
                            path="chat-history"
                            element={<ChatHistory />}
                          />
                          <Route
                            path="trade-history"
                            element={<TradeHistory />}
                          />
                          <Route
                            path="announcement-history"
                            element={<AnnouncementHistory />}
                          />
                          <Route path="command-bot" element={<CommandBot />} />
                          <Route
                            path="command-bot-legacy"
                            element={<CommandBotLegacy />}
                          />
                          <Route path="ad-builder" element={<AdBuilder />} />
                          <Route path="polls" element={<Polls />} />
                          <Route
                            path="polls/:pollId"
                            element={<ModPollResults />}
                          />
                          <Route path="stats" element={<Stats />} />
                          <Route
                            path="private-messages"
                            element={<PrivateMessages />}
                          >
                            <Route
                              path="us/:userId/pm/:privateMessageId"
                              element={<Users />}
                            />
                          </Route>
                          <Route
                            path="command-center-admin"
                            element={<CommandCenterControls />}
                          />
                          <Route
                            path="content"
                            element={
                              <ContentWrapper
                                path="/mod/content"
                                defaultPath="general"
                                navOptions={[
                                  { name: 'General', href: 'general' },
                                  { name: 'Streamers', href: 'streamers' },
                                  { name: 'Graphics', href: 'graphics' },
                                  { name: 'Schedule', href: 'schedule' },
                                  { name: 'HQ', href: 'headquarters' },
                                  {
                                    name: 'Audio Help Guide',
                                    href: 'audio-help-guide'
                                  },
                                  {
                                    name: 'T & C',
                                    href: 'terms-and-conditions'
                                  },
                                  {
                                    name: 'HQ Free',
                                    href: 'headquarters-free'
                                  },
                                  {
                                    name: 'Portfolio',
                                    href: 'portfolio',
                                    feature: FEATURES.TRADES
                                  }
                                ]}
                              />
                            }
                          >
                            <Route
                              path="general"
                              element={<ContentGeneral />}
                            />
                            <Route
                              path="headquarters"
                              element={<ConfigHeadquarters />}
                            />
                            <Route
                              path="audio-help-guide"
                              element={<AudioHelpGuide />}
                            />
                            <Route
                              path="terms-and-conditions"
                              element={<ConfigTermsAndConditions />}
                            />
                            <Route
                              path="headquarters-free"
                              element={<HeadquartersFree />}
                            />
                            <Route
                              path="portfolio"
                              element={<ConfigPortfolio />}
                            />
                            <Route path="streamers" element={<Streamers />} />
                            <Route path="schedule" element={<Schedule />} />
                            <Route path="graphics" element={<Graphics />} />
                          </Route>
                          <Route element={<RoleWrapper role={ROLES.ADMIN} />}>
                            <Route path="user-dump" element={<UserDump />} />
                            <Route path="cache" element={<Cache />} />
                            <Route
                              path="configuration"
                              element={
                                <ContentWrapper
                                  path="/mod/configuration"
                                  defaultPath="general"
                                  navOptions={[
                                    { name: 'General', href: 'general' },
                                    { name: 'Product', href: 'product' },
                                    {
                                      name: 'Memberships',
                                      href: 'memberships'
                                    }
                                  ]}
                                />
                              }
                            >
                              <Route path="general" element={<General />} />
                              <Route path="product" element={<Product />} />
                              <Route
                                path="memberships"
                                element={<Memberships />}
                              />
                            </Route>
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                  <Route path="initialize" element={<Initialize />} />
                  <Route path="login" element={<Login />} />
                  <Route path="*" element={<NotFound />} />
                  <Route path="404" element={<NotFound />} />
                  <Route path="down" element={<DownDetector />} />
                </Route>
              </Routes>
            </PageLoadWrapper>
          </BrowserRouter>
        </div>
      </AuthenticationProvider>
    </SiteConfigProvider>
  )
}

export default App
